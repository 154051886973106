<template>
  <div class="tabs-pane">
    <div class="tabs-wrapper">
      <router-link class="tab" :to="`/inventions/${$route.params.id}`">Tasks</router-link>
      <router-link class="tab" :to="`/inventions/${$route.params.id}/ifw`">IFW</router-link>
      <router-link class="tab" :to="`/inventions/${$route.params.id}/messages`">Messages</router-link>
      <router-link class="tab" :to="`/inventions/${$route.params.id}/emails`">Emails</router-link>
      <router-link class="tab" :to="`/inventions/${$route.params.id}/docketing`">Docketing</router-link>
      <router-link class="tab" :to="`/inventions/${$route.params.id}/stream`">Stream</router-link>
    </div>
    <div class="on-line-icon">
      <hub-icon
        v-if="isOnline"
        name="cloud-sync"
        size="md"
        style="color: var(--theme-success)"
        title="This invnetion is updating in real time"
      ></hub-icon>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import Icon from '../../common/Icon';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    viewMode: {
      type: String,
      default: 'composite'
    }
  },
  computed: {
    ...mapState({
      isOnline: s =>
        s.stream.isOnline || s['ifw_stream'].isOnline || s.messages.isOnline || s.emails.isOnline || s.docketing.isOnline || s.tasks.isOnline
    })
  }
};
</script>

<style lang="scss" scoped>
.tabs-pane {
  grid-row: 2;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--theme-highlight);

  .tabs-wrapper {
    display: flex;
    .tab {
      display: flex;
      user-select: none;
      justify-content: center;
      min-width: 75px;
      color: var(--theme-on-default);
      background-color: transparent;
      border: 1px solid transparent;
      padding: 8px 12px;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
        background: var(--theme-highlight);
      }
      &.router-link-exact-active {
        background-color: var(--theme-highlight);
      }
    }
  }

  .on-line-icon {
    grid-column: 5;
    justify-content: end;
  }
}
</style>
