<template>
  <div v-if="$route.params.id">
    <div v-if="inventionSync.inProgress" class="notification">
      <sync-popup :invention-sync="inventionSync" />
    </div>
    <section v-else class="invention-details">
      <div>
        <div class="header">
          <InventionHeader :id="$route.params.id" @updated="onInventionUpdated" @sync="onSync" />
        </div>
        <nav-bar v-if="inventionReady" />
      </div>
      <router-view v-if="inventionReady" class="content" />
    </section>
  </div>
  <section v-else class="notification">
    <i a style="font-style: italic">Select an invention...</i>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from '@/components/inventions/pageParts/NavBar';
import SyncPopup from '@/components/inventions/pageParts/SyncPopup';
import InventionHeader from '@/components/common/InventionHeader.vue';

export default {
  components: {
    NavBar,
    SyncPopup,
    InventionHeader
  },
  props: {},
  data() {
    return {
      id: '',
      inventionSync: {}
    };
  },
  computed: {
    ...mapState({
      my: s => s.inventions.my,
      invention: s => s.inventions.item,
      isUpdating: s => s.inventions.isUpdating,
      isGetRequestPending: s => s.inventions.isGetRequestPending
    }),
    inventionReady() {
      return !this.isGetRequestPending && this.invention?.id === this.$route.params.id;
    }
  },
  watch: {
    '$route.params.id'() {
      if (!this.$route.params.id) {
        return;
      }
      this.$trackEvent(`Invnetion (${this.$route.params.id}) opened`);
    },
    isGetRequestPending: async function(oldValue, newValue) {
      if (newValue && this.invention && this.my && !this.my.data.find(({ id }) => id === this.$route.params.id)) {
        await this.$store.dispatch('inventions/add', this.invention);
      }
    }
  },
  methods: {
    onInventionUpdated() {
      this.$store.dispatch('reports/myInventions/getCollection');
    },
    onSync(value) {
      this.inventionSync = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.invention-details {
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  height: 100%;

  .header {
    padding: 10px;
    padding-top: 5px;
  }
}

.notification {
  height: 100%;
}

.content {
  display: grid;
  align-content: stretch;
  justify-content: stretch;
  width: 100%;
  padding-left: 0.5rem;
}
</style>
