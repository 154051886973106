<template>
  <section class="notification">
    <div class="sync-status">
      <hub-icon name="autorenew" spin size="lg" class="spinner"></hub-icon>
      <div class="status-row" style="griw-row: 1;">
        <div class="check">
          <hub-icon v-if="inventionSync.applicationScheduled" name="check" size="md" />
        </div>
        <i a class="status">{{ inventionSync.applicationNumber ? 'Scheduling IFW download...' : 'Skip download IFW. No US application number' }}</i>
      </div>
      <div class="status-row" style="griw-row: 2;">
        <div class="check"><hub-icon v-if="inventionSync.fipSynced" name="check" size="md" /></div>
        <i a class="status">Synchronizing invention with FIP...</i>
      </div>
    </div>
  </section>
</template>
<script>
import Icon from '../../common/Icon';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    inventionSync: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  display: grid;
  justify-content: center;
  align-content: center;

  .sync-status {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 50px 1fr;

    .spinner {
      justify-self: center;
      align-self: center;
      grid-row: 1 / span 2;
    }

    .status-row {
      display: flex;
      grid-column: 2;
      height: 20px;

      .check {
        color: var(--theme-success);
        align-self: center;
        justify-self: flex-start;
        width: 25px;
      }

      .status {
        font-style: italic;
        align-self: center;
        justify-self: flex-start;
      }
    }
  }
}
</style>
